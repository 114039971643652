import React from "react";
import logo from "./logo.png";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <div className="repheader">
        <p>
          Catch us playing live on 11th July - 25th July on Sony Sports and
          Fancode. | Stay tuned on instagram handle @lucknowlions for latest
          updates!
        </p>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" href="#">
          <img src={logo} className="img-fluid logo" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <Link class="nav-link" to="/">
                Home
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/about-us">
                About Us
              </Link>
            </li>

            <li class="nav-item">
              <Link class="nav-link" to="/squad">
                Players
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/matches">
                Matches
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/auction">
                Auction
              </Link>
            </li>
          </ul>
          <ul>
            <button class="sponsor -auto">
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
              &nbsp;&nbsp;Connect Us{" "}
            </button>
          </ul>
        </div>
      </nav>
    </>
  );
}
