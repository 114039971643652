import React, { useEffect, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import lionshead from './lionshead.png';
export default function Fixture() {
    const [fixtures, setFixtures] = useState([]);
    const [loading, setLoading] = useState(true);


    console.log(fixtures)
    useEffect(() => {
        fetch('https://dev-upkl.upkl.in/api/resource/FIXTURES?or_filters=[["team_a_name", "=", "LUCKNOW LIONS"], ["team_b_name", "=", "LUCKNOW LIONS"]]&fields=["*"]')
            .then(response => response.json())
            .then(data => {
                 // Assuming the data structure contains the fixtures array in data.data
                const sortedData = data.data.sort((a, b) => a.match_day - b.match_day);
            setFixtures(sortedData);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching the fixtures:', error);
                setLoading(false);
            });
    }, []);

    const options = {
        loop: true,
        margin: 50,
        nav: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        dots: false,
        center: true,
        responsive: {
            0: {
                items: 1.6
            },
            600: {
                items: 3
            },
            1000: {
                items: 4.1
            }
        }
    };

    if (loading) {
        return <div> <section className="fixturesection"></section></div>;
    }

    return (
        <>
            <section className="fixturesection">
                <div className="newheading">
                    <h5>Fixtures <img src={lionshead} className='img-fluid lionicon'/></h5>
                    <div className='line'></div>
                </div>

                <OwlCarousel className="owl-theme" {...options}>
                    {fixtures.map((fixture, index) => (
                        <div className="fixturecard" key={index}>
                            <div className="row borderox">
                                <div className="col-12 p-0">
                                    <div className="fixtureright ">
                                        <div className="title">
                                            <h3>Match {index+1}</h3>
                                            <p>   {new Date(fixture.match_date).getDate()} &nbsp;
                                                {new Date(fixture.match_date).toLocaleString('default', { month: 'long' }).toUpperCase()}&nbsp;
                                                {new Date(fixture.match_date).getFullYear()}</p>
                                            <p>Time : {fixture.match_time}</p>
                                            <br></br>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <img src={`https://dev-upkl.upkl.in/${fixture.team_a_logo}`}  className="img-fluid" alt="team a logo" />
                                                <div className="score">
                                                    <h3 className="text-center pointersfixture"> {fixture.team_a_score ? ` ${fixture.team_a_score}` : '-'}</h3>

                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="vs">
                                                    <h6>VS</h6>
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <div className='teambox'>
                                                <img src={`https://dev-upkl.upkl.in/${fixture.team_b_logo}`}  className="img-fluid" alt="team b logo" />
                                                </div>
                                                
                                                <div className="score">
                                                <h3 className="text-center pointersfixture"> {fixture.team_b_score ? ` ${fixture.team_b_score}` : '-'}</h3>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="title">
                                     <div className='stadium'>
                                         <p><i class="fa fa-map-marker" aria-hidden="true"></i>
                                         &nbsp;&nbsp;&nbsp;Noida Indoor Stadium</p>
                                     </div>
                                        </div>
                                        
                                        {/* <div className='row'>
                                           <div className='col-12'>
                                           <div className='teamnamefixture'>
                                                <p>{fixture.team_a_name} VS {fixture.team_b_name}</p>
                                            </div>
                                           </div>
                                          
                                        </div> */}
                                    </div>

                                   
                                </div>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </section>
        </>
    );
}
