import React from 'react'
import Header from './Header'
import founder from './Picture1.png'
import Footer from './Footer'
import lionshead from './lionshead.png';
export default function About() {
  return (
<>
<Header/>


<div aria-label="Breadcrumb" className='breadcumb-banner'>
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">About Us</a></li>
  </ol>
</div>


<div className='aboutsection'>
    <div className='container-fluid'>
       <div className='abouttext'>
       <h3>LUCKNOW KINGS <img src={lionshead} className='img-fluid lionicon'/></h3>
       <p>Lucknow, Uttar Pradesh, also known as <b>"Kabaddi Capital" </b> of India, has a deep-rooted connection with the sport. The city boasts a rich tradition of nurturing Kabaddi talent through numerous clubs, academies, and tournaments. It has produced renowned players who have excelled both nationally and internationally, cementing its status as a hub for Kabaddi enthusiasts. Lucknow is also a frequent host of prestigious Kabaddi events, drawing participants and fans from across the country. This continual celebration and support of Kabaddi underscore Lucknow's enduring affinity and commitment to the sport, making it a focal point in India's Kabaddi landscape.</p>

<p>Among its celebrated teams, the Lucknow Lions stand out, symbolizing the city's passion and prowess in Kabaddi. This sport have consistently showcased exceptional talent, contributing significantly to the sport's growth and popularity in the region. Their success stories resonate widely, inspiring the next generation of players and deepening the city's pride in its Kabaddi heritage. With a dedicated fan base and a legacy of achievements, the Lucknow Lions is all set to shine brightly, embodying the spirit and excellence that define Kabaddi in Lucknow.</p>
       </div>
    </div>
</div>

<div className='miisionission'>
    <div className='row'>
        <div className='col-12 col-md-6'>
          <div className='mbox'>
          <h5>Mission</h5>
          <p>Our mission at Lucknow Lions is to pioneer the promotion of Kabaddi at the grassroots level in Uttar Pradesh. We are dedicated to nurturing young talent and providing them with a platform to showcase their skills on a competitive stage. Through our participation in the inaugural Uttar Pradesh Kabaddi League, we aim to inspire a new generation of Kabaddi players, fostering a culture of passion, teamwork, and sportsmanship.</p>
          <br></br>
          </div>
        </div>

        <div className='col-12 col-md-6'>
        <div className='mbox'>
            <h5>Vision</h5>
            <p>Our vision is to establish Lucknow Lions as a beacon of excellence in Kabaddi, known for developing talented athletes who uphold the values of dedication and integrity. We aspire to contribute significantly to the growth of Kabaddi in Uttar Pradesh, creating pathways for young players to excel nationally and internationally. By engaging with communities and promoting the sport at the grassroots level, we seek to leave a lasting legacy of sports empowerment and achievement across the region.</p>
      </div>  </div>
    </div>
</div>

<div className='foundermesage'>
<h3>Meet Our Founder: </h3>

   <div className='container-fluid'>
    <div className='row'>
        <div className='col-12 col-md-4'>
            <img src={founder} className='img-fluid'/>
        </div>
        <div className='col-12 col-md-8 align-self-center'>
            <div className='founderquote'>
                <p>As the founder of Lucknow Lions, I am deeply passionate about fostering Kabaddi talent from grassroots levels in Uttar Pradesh. Our vision is to ignite a new era of sportsmanship and excellence, promoting Kabaddi as a powerful vehicle for community engagement and youth empowerment across the region. Through unwavering dedication and a commitment to nurturing young athletes, we aim to establish Lucknow Lions as a symbol of integrity and achievement in Kabaddi, inspiring future generations to embrace the spirit of competition and camaraderie on and off the field.</p>
                <h6>Mr PRASHANT KUMAR SINGH</h6>
                <p><span>Founder | LUCKNOW LIONS</span></p>
            </div>
        </div>

    </div>
   </div>
</div>
<Footer/>
</>
  )
}
