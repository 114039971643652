// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Player from './Player';
import Auction from './Auction';
import Matches from './Matches';
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
      <Route path="/about-us" element={<About/>} />
      <Route path="/squad" element={<Player/>} />
      <Route path="/matches" element={<Matches/>} />
        <Route path="/auction" element={<Auction/>} />


       {/*v   <Route path="/standing" element={<Standing/>} />
        {/* <Route path="/news" element={<Newpage/>} /> */}



      </Routes>
    </Router>
  );
};

export default App;
