import React, { useEffect, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import lionshead from './lionshead.png';
import player1 from './playerimg/player1 (1).jpg'
import player2 from './playerimg/player1 (2).jpg'
import player3 from './playerimg/player1 (3).jpg'
import player4 from './playerimg/player1 (4).jpg'
import player5 from './playerimg/player1 (5).jpg'
import player6 from './playerimg/player1 (6).jpg'
import player7 from './playerimg/player1 (7).jpg'
import player8 from './playerimg/player1 (8).jpg'
export default function HomePlayer() {


    const options = {
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        dots: false,
        center: true,
        responsive: {
            0: {
                items: 1.1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3.8
            }
        }
    };


    return (
        <>
            <section className="fixturesection">
                <div className="newheading">
                    <h5>SQUAD <img src={lionshead} className='img-fluid lionicon'/></h5>
                    <div className='line'></div>
                </div>

                <OwlCarousel className="owl-theme" {...options}>
                   <div className='item'>
                    <img src={player1} className='img-fluid'/>
                   </div>

                   <div className='item'>
                    <img src={player2} className='img-fluid'/>
                   </div>


                   <div className='item'>
                    <img src={player3} className='img-fluid'/>
                   </div>


                   <div className='item'>
                    <img src={player4} className='img-fluid'/>
                   </div>


                   <div className='item'>
                    <img src={player5} className='img-fluid'/>
                   </div>


                   <div className='item'>
                    <img src={player6} className='img-fluid'/>
                   </div>

                   <div className='item'>
                    <img src={player7} className='img-fluid'/>
                   </div>
                </OwlCarousel>
            </section>
        </>
    );
}
