import React from 'react'
export default function Footer() {
  return (
  <>


   <footer>

<div className='container-fluid'>
 <div className='row'>
    <div className='col-6'>
    <div className='footernav'>
    <ul>
      <li>About Us</li>
      <li>Matches</li>
      <li>Standings</li>
      <li>Squad</li>
      <li>
      Auction </li>
    </ul>
  </div>
    </div>

    <div className='col-6 justify-content-end'>
    <div className='footernav justify-content-end'>
    <ul className='justify-content-end'>
      <li><i class="fa fa-facebook-official" aria-hidden="true"></i>
      </li>
      <li><i class="fa fa-instagram" aria-hidden="true"></i>
      </li>
     
      <li>
      <i class="fa fa-youtube-play" aria-hidden="true"></i> </li>
    </ul>
  </div>
    </div>
 </div>
</div>
</footer>

<div className='copyright'>
<p>© 2024 LUCKNOW LIONS. All Rights Reserved.</p>
</div></>
  )
}
