import React from "react";
import Header from "./Header";
import founder from "./Picture1.png";
import Footer from "./Footer";
import lionshead from "./lionshead.png";
import img1 from './01 (5).jpg';
import img2 from './02 (3).jpg';
import img3 from './03 (2).jpg';
export default function Auction() {
  return (
    <>
      <Header />

      <div aria-label="Breadcrumb" className="breadcumb-banner">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="//">Auction </a>
          </li>
        </ol>
      </div>

      <section className="gallery">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="auctionimg">
                <img src={img1} className="img-fluid"/>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="auctionimg">
                <img src={img2} className="img-fluid"/>
              </div>
            </div>


            <div className="col-12 col-md-4">
              <div className="auctionimg">
                <img src={img3} className="img-fluid"/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
