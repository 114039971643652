import React, { useState, useEffect } from "react";
import Header from './Header'
import Footer from "./Footer";
export default function Matches() {

    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchMatches = async () => {
        try {
          const response = await fetch(
            'https://dev-upkl.upkl.in/api/resource/FIXTURES?or_filters=[["team_a_name", "=", "LUCKNOW LIONS"], ["team_b_name", "=", "LUCKNOW LIONS"]]&fields=["*"]&limit_page_length=100'
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const data = await response.json();
  
          // Sort by position field
          const sortedData = data.data.sort((a, b) => a.match_number - b.match_number);
  
          setMatches(sortedData);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
  
      fetchMatches();
    }, []);
  
    // Group matches by match_date
    const groupMatchesByDate = (matches) => {
      return matches.reduce((acc, match) => {
        const matchDate = new Date(match.match_date).toDateString();
        if (!acc[matchDate]) {
          acc[matchDate] = [];
        }
        acc[matchDate].push(match);
        return acc;
      }, {});
    };
  
    const groupedMatches = groupMatchesByDate(matches);


  return (

    
  <>
<Header/>
<div aria-label="Breadcrumb" className='breadcumb-banner'>
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Matches</a></li>
  </ol>
</div>



{loading ? (
              <p className="text-white">Loading...</p>
            ) : (
              Object.keys(groupedMatches).map((date) => (


<div class="match_today container-fluid" id="match_today">

    <br></br>
<h2 key={date}>{date}</h2>

<div class="widget-content">

{groupedMatches[date].map((match, index) => (
<div class="mnajiri">
     <a class="fullink" href="#"></a>
       <div class="mnajiriin">
         <div class="mpart1">
           <div class="right_match">
              <span class="right_tech">
              <img  src={`https://dev-upkl.upkl.in/${match.team_b_logo}`} className="float-right img-fluid" alt="Team Logo" />                  <div class="fname">.</div>
               </span>
           </div>
                 <strong class="najiri1">VS </strong>
                 <div class="left_match">
                   <span class="left_tech">
                   <img  src={`https://dev-upkl.upkl.in/${match.team_a_logo}`} className="float-right img-fluid" alt="Team Logo" />                   
                       <div class="fname"> .</div>
                   </span>
                </div>
            </div>
               <div class="details_match">
                  <span class="first_match">
                    {match.team_b_name}
                   </span>
                   <span class="thany mnone"><i class="fa fa-clock-o" aria-hidden="true"></i>
                   &nbsp;&nbsp;{match.match_time}
                   </span>
                   <span className="thany liga_mdw">{match.team_a_name}
                   </span>
              </div>
      </div>
   </div>

))}
   </div>

   </div>
   
))
)}
<br></br>
<Footer/>
   </>
  )
}
