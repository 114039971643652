import "./App.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Fixture from "./Fixture";
import banner from "./lions winner .jpg";
import Table from "./Table";
import HomePlayer from "./HomePlayer";
import v1 from "./v1.mp4";
import v2 from "./v2.mp4";
import v3 from "./v3.mp4";
import Header from "./Header";
import Footer from "./Footer";
import img from './banner-005 png.png'

function Home() {
  const options = {
    loop: true,
    margin: 28,
    nav: true,
    responsive: {
      0: {
        items:1.01,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5.3,
      },
    },
  };

  const options1 = {
    loop: false,
    margin: 20,
    nav: true,
    responsive: {
      0: {
        items:2.2,
        margin: 15,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5.3,
      },
    },
  };
  return (
    <>
      <Header />

      <section className="bannerimg">
        <img src={banner} className="img-fluid" />
      </section>

      <Fixture />

      <section className="playerdata">
        <div className="newheading">
          <h5 className="text-white text-center">Standings</h5>
          <div className="line"></div>
        </div>

        <Table />
      </section>

      <section>
        <HomePlayer />
      </section>

      <section className="playerdata">
        <div className="newheading">
          <h5 className="text-white"> VIDEOS</h5>
          <div className="line"></div>
        </div>
        <OwlCarousel className="owl-theme" {...options1}>
          <div className="item">
            <video
              playsinline="playsinline"
              muted ="true"
              preload="yes"
              autoplay="autoplay"
              loop="loop"
              controls
            >
              <source src={v1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="item">
            <video
              playsinline="playsinline"
              muted ="true"
              preload="yes"
              autoplay="autoplay"
              loop="loop"
              controls
            >
              <source src={v2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="item">
            <video muted ="true"
              playsinline="playsinline"
              preload="yes"
              autoplay="autoplay"
              loop="loop"
              controls
            >
              <source src={v3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="item">
            <video
              playsinline="playsinline"
              muted ="true"
              preload="yes"
              autoplay="autoplay"
              loop="loop"
              controls
            >
              <source src={v1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="item">
            <video
              playsinline="playsinline"
              muted ="true"
              preload="yes"
              autoplay="autoplay"
              loop="loop"
              controls
            >
              <source src={v2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>


          <div className="item">
            <video
              playsinline="playsinline"
              muted ="true"
              preload="yes"
              autoplay="autoplay"
              loop="loop"
              controls
            >
              <source src={v3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="item">
            <video
              playsinline="playsinline"
              muted ="true"
              preload="yes"
              autoplay="autoplay"
              loop="loop"
              controls
            >
              <source src={v2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </OwlCarousel>
      </section>


      <section className='watchnow'>
    <div className='row'>
      <div className='col-10 offset-1'>
<div className='watchbanner'>
  <img src={img}  className='img-fluid'/>
</div>
      </div>
    </div>

  </section>
      <Footer />
    </>
  );
}

export default Home;
