import React from "react";
import Header from "./Header";
import founder from "./Picture1.png";
import player1 from "./playerimg/player1 (1).jpg";
import player2 from "./playerimg/player1 (2).jpg";
import player3 from "./playerimg/player1 (3).jpg";
import player4 from "./playerimg/player1 (4).jpg";
import player5 from "./playerimg/player1 (5).jpg";
import player6 from "./playerimg/player1 (6).jpg";
import player7 from "./playerimg/player1 (7).jpg";
import player8 from "./playerimg/player1 (8).jpg";
import Footer from "./Footer";
export default function Player() {
  return (
    <>
      <Header />

      <div aria-label="Breadcrumb" className="breadcumb-banner">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="//">Lucknow Squad</a>
          </li>
        </ol>
      </div>

      <section className="lucknowteam">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="playerpict">
                <img src={player1} className="img-fluid" />
              </div>
            </div>

            <div className="col-md-3">
              <div className="playerpict">
                <img src={player2} className="img-fluid" />
              </div>
            </div>

            <div className="col-md-3">
              <div className="playerpict">
                <img src={player3} className="img-fluid" />
              </div>
            </div>

            <div className="col-md-3">
              <div className="playerpict">
                <img src={player4} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="playerpict">
                <img src={player5} className="img-fluid" />
              </div>
            </div>

            <div className="col-md-3">
              <div className="playerpict">
                <img src={player6} className="img-fluid" />
              </div>
            </div>

            <div className="col-md-3">
              <div className="playerpict">
                <img src={player7} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="playerpict">
                <img src={player8} className="img-fluid" />
              </div>
            </div>


          </div>
        </div>
      </section>

      <Footer/>
    </>
  );
}
